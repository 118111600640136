<template>
  <div :class="{ 'is-flex': getModuleConfig('card_layout') === 'flex' }">
    <div class="top" :style="topStyle">
      <Header
        :meta="meta"
        :page-title="getModuleConfig('page_title')"
        :page-image="getModuleConfig('page_image')"
        :page-kv-image="getModuleConfig('page_kv_image')"
        :header-section="getModuleConfig('header_section')"
      />
      <div class="py-3 px-2">
        <div
          v-if="getModuleConfig('member_card_section.enable') == true"
          class="card"
          :class="`layout-${getModuleConfig(
            'member_card_section.card_layout'
          )}`"
          :style="cardBackground"
        >
          <div class="card__logo">
            <img
              v-if="getModuleConfig('member_card_section.display_logo')"
              :src="
                (getModuleConfig('member_card_section.use_level_logo') &&
                  user.level_logo_url) ||
                getModuleConfig('member_card_section.logo')
              "
            />
          </div>

          <div
            class="card__name"
            v-if="getModuleConfig('member_card_section.display_name') == true"
          >
            {{ user.name }}
          </div>
          <div class="card__avatar">
            <img
              v-if="
                getModuleConfig(
                  'member_card_section.display_customer_avatar'
                ) == true
              "
              :src="user.avatar_url"
              class="rounded-circle"
            />
          </div>

          <div
            class="card__level"
            v-if="
              getModuleConfig(
                'member_card_section.display_customer_level_name'
              ) == true &&
              user.level_name &&
              !unbinded
            "
            :style="{
              color: `${getModuleConfig(
                'member_card_section.customer_level_name_color'
              )}`,
              'border-color': `${getModuleConfig(
                'member_card_section.customer_level_name_color'
              )}`,
            }"
          >
            {{ user.level_name }}
          </div>

          <div class="card__point">
            <div
              v-if="
                getModuleConfig('member_card_section.display_points') == true &&
                !unbinded
              "
              class="card__point__title"
              :style="{
                color: `${getModuleConfig(
                  'member_card_section.customer_points_name_color'
                )}`,
              }"
            >
              {{ displayPointsName }}
            </div>
            <div
              v-if="
                getModuleConfig('member_card_section.display_points') == true &&
                !unbinded
              "
              class="card__point__number"
              :style="{
                color: `${getModuleConfig(
                  'member_card_section.customer_points_color'
                )}`,
              }"
            >
              {{ userPoints }}
            </div>
          </div>

          <div
            v-if="
              getModuleConfig('member_card_section.display_mini_barcode') ==
                true && !unbinded
            "
            class="card__minibarcode"
          >
            <button @click="$refs.minibarcode.open()">
              <img src="./images/mini-barcode.svg" />
            </button>

            <div class="barcode-modal">
              <popup ref="minibarcode" :noActions="true" :scrolling="true">
                <div class="card p-4">
                  <p v-if="showBarcodeError" class="s-text-danger">
                    barcode 產生錯誤，請聯絡相關人員
                  </p>
                  <img v-else id="card__barcode-img" :src="barcode" />
                </div>
              </popup>
            </div>
          </div>
        </div>

        <div
          class="card__barcode my-3"
          v-if="
            getModuleConfig('member_card_section.display_barcode') == true &&
            !unbinded
          "
        >
          <!-- <img class="" src="./images/barcode.png" /> -->
          <p v-if="showBarcodeError" class="s-text-danger">
            barcode 產生錯誤，請聯絡相關人員
          </p>
          <img v-else id="card__barcode-img" :src="barcode" />
        </div>
      </div>
    </div>

    <div class="p-2">
      <div class="info" v-if="!unbinded">
        <div class="px-3 py-2">
          <h3 class="info__title">
            {{ getModuleConfig("unbind_page.form_title") }}
          </h3>
          <div class="info__desc">
            {{ getModuleConfig("unbind_page.form_desc") }}
          </div>
        </div>
        <div
          class="info__content"
          v-html="getModuleConfig('unbind_page.content')"
        ></div>
      </div>
      <div v-else class="text-center">已解除綁定</div>
    </div>

    <div class="page__button">
      <template v-if="!unbinded">
        <SharedButton
          v-if="getModuleConfig('unbind_page.unbind_button_enable')"
          @click="showUnbind"
          class="s-btn-bg-primary"
        >
          {{ getModuleConfig("unbind_page.unbind_button_text") }}
        </SharedButton>
        <MemberButton
          :button-text="getModuleConfig('unbind_page.button_text')"
          :type="getModuleConfig('unbind_page.button_action')"
          :button-url="getModuleConfig('unbind_page.button_url')"
          class="mt-2 px-3 w-full text-nowrap s-btn-outline-primary"
        ></MemberButton>
      </template>
      <MemberButton
        v-else
        buttonText="回 LINE 官方帳號"
        type="OA"
        class="mt-2 px-3 w-full text-nowrap s-btn-outline-primary"
      ></MemberButton>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Page/Liff/Shared/HeaderV3";
import { mapGetters } from "vuex";
import bwipjs from "bwip-js";
import pointApi from "@/apis/liff/v2/point";
import moduleMap from "@/mixins/liff/memberCenter/moduleMap";
import authApi from "@/apis/liff/v2/auth";
import registerApi from "@/apis/liff/v2/register";
import Popup from "@/components/Page/Liff/Shared/Popup.vue";
import SharedButton from "@/components/Page/Liff/Shared/Button.vue";
import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";
import Swal from "sweetalert2";

export default {
  mixins: [moduleMap],
  components: {
    Header,
    Popup,
    SharedButton,
    MemberButton,
  },
  props: {
    isFlexLayout: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showBarcodeError: false,
      barcode: null,
      userPoints: "-",
      unbinded: false,
    };
  },
  computed: {
    ...mapGetters("liffAuth", ["user"]),
    ...mapGetters({
      meta: "liffGeneral/meta",
    }),
    displayPointsName() {
      return (
        this.getModuleConfig("member_card_section.points_name") ?? "紅利點數"
      );
    },
    topStyle() {
      let otherStyle = {};
      if (this.getModuleConfig("member_card_section")) {
        otherStyle = this.checkColorValue(
          this.getModuleConfig("member_card_section.layout_bg_top_color"),
          "--liff-card_bg_top_color",
          otherStyle
        );
        otherStyle = this.checkColorValue(
          this.getModuleConfig("member_card_section.layout_bg_bottom_color"),
          "--liff-card_bg_bottom_color",
          otherStyle
        );
        otherStyle = this.checkColorValue(
          this.getModuleConfig("member_card_section.customer_name_color"),
          "--liff-card_name_text_color",
          otherStyle
        );
      }
      return otherStyle;
    },
    cardBackground() {
      let style = [];

      if (this.getModuleConfig("member_card_section.display_card_luster")) {
        style.push(
          `linear-gradient(135deg,${this.getModuleConfig(
            "member_card_section.card_luster_left_left"
          )} 0%,${this.getModuleConfig(
            "member_card_section.card_luster_left_right"
          )} 50%,${this.getModuleConfig(
            "member_card_section.card_luster_right_left"
          )} 50%,${this.getModuleConfig(
            "member_card_section.card_luster_right_right"
          )} 100%)`
        );
      }
      // else {
      //   style.push(
      //     `linear-gradient(135deg,rgba(255, 255, 255, 0) 0%,rgba(0, 0, 0, .05) 50%,rgba(255, 255, 255, .2) 50%,rgba(255, 255, 255, 0) 100%)`
      //   );
      // }

      if (this.getModuleConfig("member_card_section.display_card_picture")) {
        if (
          this.getModuleConfig("member_card_section.use_level_card_picture") &&
          this.user.level_card_picture_url
        ) {
          style.push(`url('${this.user.level_card_picture_url}')`);
        } else if (
          this.getModuleConfig("member_card_section.card_picture_url")
        ) {
          style.push(
            `url('${this.getModuleConfig(
              "member_card_section.card_picture_url"
            )}')`
          );
        }
      }

      return { background: style.join(",") };
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    checkColorValue(config, styleName, styleObject) {
      if (config !== undefined && config !== "#" && config !== "") {
        return Object.assign({ [styleName]: config }, styleObject);
      }
      return styleObject;
    },
    async init() {
      // 檢查是否有啟用強制綁定及綁定狀態
      let { isEnabledForceBind, execBindResult } =
        await authApi.checkForceBind();

      if (
        isEnabledForceBind &&
        execBindResult === "external_customer_not_found"
      ) {
        this.$router.push({
          name: "LiffRegisterNotRegister",
        });
      }

      // 只有當啟用顯示 barcode 時，才 generate barcode

      if (
        this.getModuleConfig("member_card_section.display_barcode") === true ||
        this.getModuleConfig("member_card_section.display_mini_barcode") ===
          true
      ) {
        this.generateBarcode();
      }

      if (this.getModuleConfig("member_card_section.display_points") === true) {
        const data = await pointApi.getAvailablePoints();
        this.userPoints = data.point;
      }
    },

    generateBarcode() {
      let canvasElement = document.createElement("canvas");

      try {
        bwipjs.toCanvas(canvasElement, {
          bcid: "code128", // Barcode type
          text: this.user.customer_number,
          scale: 2, // 3x scaling factor
          height: 10, // Bar height, in millimeters
          includetext: true, // Show human-readable text
          textxalign: "center", // Always good to set this
        });
        //document.getElementById("card__barcode-img").src =
        this.barcode = canvasElement.toDataURL("image/png");
        this.showBarcodeError = false;
      } catch (e) {
        console.log("barcode generate error", e);
        this.showBarcodeError = true;
      }
    },

    getModuleConfig(key) {
      return this.$store.getters["liffModule/getConfig"]("member_center", key);
    },
    showUnbind() {
      Swal.fire({
        type: "warning",
        title: "確定解除綁定嗎？",
        showCancelButton: true,
        confirmButtonText: "確定解除綁定",
        cancelButtonText: "不解除",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.unbind();
        }
      });
    },
    async unbind() {
      try {
        await registerApi.unbind();
        this.unbinded = true;
      } catch (e) {
        console.log("unbind error", e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "style.scss";

.barcode-modal {
  ::v-deep .popup__header {
    margin-bottom: 0px;
  }

  ::v-deep .popup__panel {
    background-color: transparent;

    padding: 0;

    box-shadow: none;
  }

  ::v-deep .popup__body.scrolling {
    height: auto;
  }

  .card {
    background: white;
    min-height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.info {
  border-radius: 10px;
  border: solid 1px #e5e5ea;
  background: white;

  &__title {
    font-size: 18px;
    font-weight: 600;
  }

  &__desc {
    font-size: 16px;
  }

  &__content {
    border-top: solid 1px #e5e5ea;
    font-size: 14px;
    padding: 16px;
  }
}
</style>
