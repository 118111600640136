var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'is-flex': _vm.getModuleConfig('card_layout') === 'flex' }},[_c('div',{staticClass:"top",style:(_vm.topStyle)},[_c('Header',{attrs:{"meta":_vm.meta,"page-title":_vm.getModuleConfig('page_title'),"page-image":_vm.getModuleConfig('page_image'),"page-kv-image":_vm.getModuleConfig('page_kv_image'),"header-section":_vm.getModuleConfig('header_section')}}),_c('div',{staticClass:"py-3 px-2"},[(_vm.getModuleConfig('member_card_section.enable') == true)?_c('div',{staticClass:"card",class:("layout-" + (_vm.getModuleConfig(
          'member_card_section.card_layout'
        ))),style:(_vm.cardBackground)},[_c('div',{staticClass:"card__logo"},[(_vm.getModuleConfig('member_card_section.display_logo'))?_c('img',{attrs:{"src":(_vm.getModuleConfig('member_card_section.use_level_logo') &&
                _vm.user.level_logo_url) ||
              _vm.getModuleConfig('member_card_section.logo')}}):_vm._e()]),(_vm.getModuleConfig('member_card_section.display_name') == true)?_c('div',{staticClass:"card__name"},[_vm._v(" "+_vm._s(_vm.user.name)+" ")]):_vm._e(),_c('div',{staticClass:"card__avatar"},[(
              _vm.getModuleConfig(
                'member_card_section.display_customer_avatar'
              ) == true
            )?_c('img',{staticClass:"rounded-circle",attrs:{"src":_vm.user.avatar_url}}):_vm._e()]),(
            _vm.getModuleConfig(
              'member_card_section.display_customer_level_name'
            ) == true &&
            _vm.user.level_name &&
            !_vm.unbinded
          )?_c('div',{staticClass:"card__level",style:({
            color: ("" + (_vm.getModuleConfig(
              'member_card_section.customer_level_name_color'
            ))),
            'border-color': ("" + (_vm.getModuleConfig(
              'member_card_section.customer_level_name_color'
            ))),
          })},[_vm._v(" "+_vm._s(_vm.user.level_name)+" ")]):_vm._e(),_c('div',{staticClass:"card__point"},[(
              _vm.getModuleConfig('member_card_section.display_points') == true &&
              !_vm.unbinded
            )?_c('div',{staticClass:"card__point__title",style:({
              color: ("" + (_vm.getModuleConfig(
                'member_card_section.customer_points_name_color'
              ))),
            })},[_vm._v(" "+_vm._s(_vm.displayPointsName)+" ")]):_vm._e(),(
              _vm.getModuleConfig('member_card_section.display_points') == true &&
              !_vm.unbinded
            )?_c('div',{staticClass:"card__point__number",style:({
              color: ("" + (_vm.getModuleConfig(
                'member_card_section.customer_points_color'
              ))),
            })},[_vm._v(" "+_vm._s(_vm.userPoints)+" ")]):_vm._e()]),(
            _vm.getModuleConfig('member_card_section.display_mini_barcode') ==
              true && !_vm.unbinded
          )?_c('div',{staticClass:"card__minibarcode"},[_c('button',{on:{"click":function($event){return _vm.$refs.minibarcode.open()}}},[_c('img',{attrs:{"src":require("./images/mini-barcode.svg")}})]),_c('div',{staticClass:"barcode-modal"},[_c('popup',{ref:"minibarcode",attrs:{"noActions":true,"scrolling":true}},[_c('div',{staticClass:"card p-4"},[(_vm.showBarcodeError)?_c('p',{staticClass:"s-text-danger"},[_vm._v(" barcode 產生錯誤，請聯絡相關人員 ")]):_c('img',{attrs:{"id":"card__barcode-img","src":_vm.barcode}})])])],1)]):_vm._e()]):_vm._e(),(
          _vm.getModuleConfig('member_card_section.display_barcode') == true &&
          !_vm.unbinded
        )?_c('div',{staticClass:"card__barcode my-3"},[(_vm.showBarcodeError)?_c('p',{staticClass:"s-text-danger"},[_vm._v(" barcode 產生錯誤，請聯絡相關人員 ")]):_c('img',{attrs:{"id":"card__barcode-img","src":_vm.barcode}})]):_vm._e()])],1),_c('div',{staticClass:"p-2"},[(!_vm.unbinded)?_c('div',{staticClass:"info"},[_c('div',{staticClass:"px-3 py-2"},[_c('h3',{staticClass:"info__title"},[_vm._v(" "+_vm._s(_vm.getModuleConfig("unbind_page.form_title"))+" ")]),_c('div',{staticClass:"info__desc"},[_vm._v(" "+_vm._s(_vm.getModuleConfig("unbind_page.form_desc"))+" ")])]),_c('div',{staticClass:"info__content",domProps:{"innerHTML":_vm._s(_vm.getModuleConfig('unbind_page.content'))}})]):_c('div',{staticClass:"text-center"},[_vm._v("已解除綁定")])]),_c('div',{staticClass:"page__button"},[(!_vm.unbinded)?[(_vm.getModuleConfig('unbind_page.unbind_button_enable'))?_c('SharedButton',{staticClass:"s-btn-bg-primary",on:{"click":_vm.showUnbind}},[_vm._v(" "+_vm._s(_vm.getModuleConfig("unbind_page.unbind_button_text"))+" ")]):_vm._e(),_c('MemberButton',{staticClass:"mt-2 px-3 w-full text-nowrap s-btn-outline-primary",attrs:{"button-text":_vm.getModuleConfig('unbind_page.button_text'),"type":_vm.getModuleConfig('unbind_page.button_action'),"button-url":_vm.getModuleConfig('unbind_page.button_url')}})]:_c('MemberButton',{staticClass:"mt-2 px-3 w-full text-nowrap s-btn-outline-primary",attrs:{"buttonText":"回 LINE 官方帳號","type":"OA"}})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }